<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>站内消息</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/deriveMessage' }">消息队列</el-breadcrumb-item>
                <el-breadcrumb-item>未打卡用户</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="content-list-page page-content">
            <div class="table-content">

                <el-table
                    :header-cell-style="{padding:0}"
                    :cell-class-name="rowItem"
                    ref="singleTable"
                    :data="tableData"
                    highlight-current-row
                    @current-change="handleCurrentChange"
                    v-loading="loading"
                    style="width: 100%;background: #fff;">
                    <el-table-column
                        label="ID"
                        property="id"
                        width="200">
                    </el-table-column>
                    <el-table-column
                        width="200"
                        label="用户头像">
                        <template slot-scope="scope">
                            <el-avatar :size="50" :src="scope.row.avatar_url"></el-avatar>
                        </template>
                    </el-table-column>

                    <el-table-column
                        width="200"
                        property="name"
                        label="用户昵称">
                    </el-table-column>
                    <el-table-column>
                    </el-table-column>
                </el-table>

            </div>

            <div class="table-batch" >
                <span class="fl-r" >
                    <el-pagination
                        small
                        background
                        :current-page="page"
                        :page-sizes="[10, 20]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="setPageSize"
                        @current-change="setPage"
                        :total="total">
                </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
    export default {
        name: "detail",
        data(){
            return {
                classId: 0,
                date:'',
                page:1,
                pageSize:10,
                total:0,
                loading:false,
                tableData: [],
                currentRow: null
            }
        },
        methods:{
            ...mapActions('message', ['listClassUnSignedUser']),
            setCurrent(row) {
                this.$refs.singleTable.setCurrentRow(row);
            },
            handleCurrentChange(val) {
                this.currentRow = val;
            },
            headeCellStyle({row, rowIndex}) {
                return '{padding:0}';
            },
            rowItem(){
                return 'row-item';
            },
            search(){
                this.tableData = []
                this.page = 1
                this.getList()
            },
            async getList(){
                this.loading = true
                let postData = {
                    classId: this.classId,
                    date: this.date,
                    page:this.page,
                    pageSize: this.pageSize
                }
                const { res_info , data } = await this.listClassUnSignedUser(postData)
                this.loading = false
                if(res_info != 'ok'){
                    return
                }
                this.tableData = data.list
                this.total = data.total
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page){
                this.page  = page
                this.getList()
            },
        },
        mounted () {
            this.classId = this.$route.params.classId
            this.date = this.$route.params.date
            this.getList()
        }
    }
</script>

<style>

</style>
